import AntForm, { AntSchema, useAntForm } from "@9troisquarts/ant-form";
import { Button, Card, Col, Row, Space } from "antd";
import React from "react";
import { useIntl } from "react-intl";
import { UserType } from "../../../types";
import post from "../../../../utils/httpPost";
import { castAttributesFromDefinition, ModelDefinitionType } from '@9troisquarts/wand'
import { useHistory } from 'react-router-dom'
import useI18n from "../../../../utils/useI18n";
import config from '../../../../config';
import styled from '@emotion/styled'
import microsoftLogo from './images/ms-symbollockup_signin_light.svg';

const Layout = styled.div`
  display: block;
  text-align: center;
  height: 100vh;
  background-color: #188fba;
`

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const LogoContainer = styled.div`
  display: block;
  > img {
    width: 200px;
    margin: 20px 0;
  }
`


type SignInProps = {
  user: UserType;
  errors: any;
};

const env = process.env.NODE_ENV || 'development';

const userDefinition: ModelDefinitionType = {
  email: 'String',
  password: 'Password',
};

type Provider = 'saml' | 'developer'

const redirectToSSO = (provider: Provider) => {
  const providerConfig = config.authentication?.providers[provider].environments[env];
  window.location.href = `${providerConfig.authUrl}`;
};

const SignIn: React.FC<SignInProps> = props => {
  const {
    user,
    errors,
  } = props;
  const { t } = useI18n();

  const signInUser = (user: UserType) => post('/users/sign_in', {
    ...user
  }, "user")
  const history = useHistory();

  const onSubmit = () => {
    signInUser(castAttributesFromDefinition(userDefinition, object));
  };

  const { object, onChange } = useAntForm(user || {});
  const intl = useIntl();

  const SignInSchema: AntSchema = [
    {
      name: 'email',
      label: intl.formatMessage({ id: 'words.email' }),
      input: {
        type: 'string',
      },
      colProps: {
        xs: 24,
        md: 24,
      },
    },
    {
      name: 'password',
      label: intl.formatMessage({ id: 'words.password' }),
      input: {
        type: 'password',
      },
      colProps: {
        xs: 24,
        md: 24,
      },
    },
  ]

  return (
    <Layout>
      <Container>
        {config.authentication.logo && (
          <LogoContainer>
            <img src={config.authentication.logo} />
          </LogoContainer>
        )}
        <Space direction="vertical">
          {config.authentication?.providers?.saml?.enable && config.authentication?.providers?.saml?.environments[env]?.authUrl && (
            <Row>
              <Col xs={24}>
                <a onClick={() => redirectToSSO('saml')}>
                  <img src={microsoftLogo} />
                </a>
              </Col>
            </Row>
          )}
          {config.authentication?.providers?.developer?.enable && config.authentication?.providers?.developer?.environments[env]?.authUrl && (
            <Row style={{ marginTop: '50px' }}>
              <Col xs={24}>
                <Button onClick={() => redirectToSSO('developer')}>
                  Sign in as developer
                </Button>
              </Col>
            </Row>
          )}
        </Space>
      </Container>
    </Layout>
  )
};

export default SignIn;