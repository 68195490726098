import SignIn from './pages/SignIn';

type Route = {
  path: string;
  name: string;
  key?: string;
  component?: React.ReactNode;
  hideInMenu?: boolean;
  children?: Route[];
}

const routes: Route[] = [
  {
    path: '/users/sign_in',
    name: 'sign_in',
    component: SignIn,
  },
];

export default routes;