// @ts-nocheck
import React from 'react';
import { Layout as AntdLayout } from 'antd';
import styles from './index.module.sass';
import classnames from 'classnames/bind';
import { showNotification } from '../../../../utils/handleNotifications';
import { typeFromAST } from 'graphql';

const cx = classnames.bind(styles)

const {
  Content,
  Footer
} = AntdLayout;

export function urlToList(url) {
  if (!url || url === '/') {
    return ['/'];
  }

  var urlList = url.split('/').filter(function (i) {
    return i;
  });
  return urlList.map(function (urlItem, index) {
    return "/".concat(urlList.slice(0, index + 1).join('/'));
  });
}

const flashTypeFor = type => {
  switch(type) {
    case 'notice':
      return 'info';
    case 'alert':
      return 'error';
    default:
      return type;
  }
}
interface IProps {
  children: React.ReactNode;
  user: any;
};

const Layout: React.FC<IProps> = props => {
  const {
    children,
    flash,
  } = props;

  flash && flash.forEach((f) => {
    showNotification({ type: flashTypeFor(f[0]), message: f[1]})
  })

  return (
    <AntdLayout
      id="layout-mybonus"
    >
      <Content className={styles.contentWrapper} style={{ padding: 0 }}>
        {children}
      </Content>
    </AntdLayout>
  );
};

export default Layout;
